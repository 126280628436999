.our-services-container {
  width: 100%;
  height: 100vh;
  position: relative;
  scroll-snap-align: center;
  background-color: #1943cd;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/images/Road.png");
  background-repeat: no-repeat;
  background-position: center;
  padding-left: 10px;
  padding-right: 10px;

  @media (max-width: 600px) {
    padding: 0;
    background-position: left;
    background-size: cover;
  }
}

.our-services-content-container {
  width: 1104px;

  @media (max-width: 600px) {
    padding: 0;
    margin: 0;
    height: 100%;
  }
}

.trucking-services-label-container {
  width: 50px !important;
  @media (max-width: 600px) {
    width: 100% !important;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    margin-top: 15px;
  }
}

.trucking-services-label {
  color: #ffffff;
  font-size: 65px;

  @media (max-width: 600px) {
    font-size: 26px;
  }
}

.our-services-card-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  @media (max-width: 600px) {
    flex-direction: column;
    margin-top: 0;
  }
}
