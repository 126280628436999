.cards-container {
  background-image: linear-gradient(#75beff, #f3f5ff);
  background-color: white;
  padding: 20px;
  height: 468px;
  width: 352px;
  margin-right: 30px;
  border-radius: 8px;

  @media (max-width: 600px) {
    height: 22vh;
    width: 40vw;
    margin-bottom: 15px;
    margin-right: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    img {
      position: inherit;
      height: 100%;
      width: 100%;
      background-size: cover;
    }
  }
}

.cards-image-container {
  @media (max-width: 600px) {
    height: 50%;
    width: 100%;
  }
}

.cards-label {
  color: #001456;
  font-size: 38px;
  margin: 0;
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    font-size: 18px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

.cards-description {
  color: #1b1b1b;
  font-size: 18px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}
