.partner-with-us-container {
  width: 100%;
  height: 100vh;
  position: relative;
  scroll-snap-align: center;
}

.tires {
  position: absolute;
  bottom: -100px;
  width: 100vw;
  z-index: 100;
}

.left-container {
  margin: 0px;
  padding: 0px;
  height: 438px;
  width: 596px;
  margin-left: 81px;

  @media (max-width: 600px) {
    margin-left: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.content {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: calc(100% - 156px);

  @media (max-width: 600px) {
    width: 100%;
    position: relative;
    align-items: center;

    img.truck {
      display: none;
    }
  }
}

.business-label {
  font-size: 20px;
  font-family: "Archivo", sans-serif;
  font-weight: bold;
  border-width: 0px;
  border-radius: 0px;
  margin-bottom: 8px;
  color: #2851de;

  @media (max-width: 600px) {
    font-size: 12px;
  }
}

.description {
  font-size: 55px;
  font-family: "Archivo", sans-serif;
  border-width: 0px;
  border-radius: 0px;
  margin-bottom: 16px;

  @media (max-width: 600px) {
    font-size: 32px;
  }
}

.column {
  display: flex;
  flex-direction: column;
}
