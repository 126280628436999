.contact-us-container {
  background-color: blueviolet;
  width: 100%;
  height: 100vh;
  position: relative;
  scroll-snap-align: center;

  .truck-image {
    position: absolute;
    top: 1px;
    right: 200px;
  }
}

.contact-us-top-container {
  height: 50%;
  width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 150px;

  h1 {
    color: #1b1b1b;
    font-size: 48px;
    font-weight: normal;
    margin: 0;
    padding: 0;
  }

  .contact-us-clients-label {
    color: #2654ee;
  }
}

.contact-us-bottom-container {
  position: relative;
  height: 50%;
  width: 100%;
  background-color: #1943cd;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  label {
    color: #ffffff;
    margin: 0;
    padding: 0;
  }
}

.contact-us-client-cards-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.get-in-touch-container {
  width: 595px;
  margin-left: 150px;
  margin-top: 70px;
  margin-bottom: 20px;
  h1 {
    color: #ffffff;
    font-size: 52px;
    font-weight: normal;
    margin: 0;
    padding: 0;
    /* padding-top: 80px;
    margin-bottom: 20px; */
  }
}

.explore-container {
  margin-top: 70px;
  height: 100%;
  h1 {
    color: #ffffff;
    font-size: 52px;
    font-weight: normal;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
    /* padding-top: 80px;
    margin-bottom: 20px; */
  }
}

.contact-us-icon-with-label-container {
  margin-left: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  img {
    margin-right: 10px;
    height: 16px;
    width: 16px;
  }

  label {
    font-size: 20px;
  }
}

.contact-us-row {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}

.contact-us-column {
  display: flex;
  flex-direction: column;

  a {
    text-decoration: none;
    margin-bottom: 20px;
    font-size: 20px;
  }

  a:hover {
    opacity: 0.5;
    cursor: pointer;
  }
}

.space-between {
  justify-content: space-between;
}

.footer {
  align-items: center;
  padding-left: 150px;
  padding-right: 150px;
  height: 104px;
  border-top: 1px solid #ffffff;
  .company-name {
    margin-right: 50px;
  }

  img {
    margin-right: 10px;
  }
}

.arrow-up-btn {
  position: absolute;
  right: 40px;
  bottom: 140px;
  height: 85px;
  width: 85px;
  border-radius: 42px;
  border: 1px solid #ffffff;
  background-color: #1943cd;
}

.arrow-up-btn:hover {
  cursor: pointer;
  opacity: 0.5;
}

a {
  text-decoration: none;
  color: #ffffff;
}

a:hover {
  opacity: 0.5;
}
