.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 156px;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  margin: 0;

  @media (max-width: 600px) {
    height: 120px;
  }
}

.logo-container {
  padding-left: 32px;
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    height: 60%;
    padding-left: 18px;
  }
}

.logo-container img {
  height: 92px;
  width: 209px;

  @media (max-width: 600px) {
    height: 62px;
    width: 150px;
  }
}

.links-container {
  padding-right: 32px;
  display: flex;
  align-items: center;
  color: "#1B1B1B";
  height: 76px;
  @media (max-width: 600px) {
    display: none;
  }
}

.tabs {
  margin-right: 24px;
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Archivo", sans-serif;
  background-color: transparent;
  border-width: 0;
  cursor: pointer;
}

.tabs:hover {
  opacity: 0.5;
  font-size: 16px;
  transition: all 200ms;
}

.contact-us-btn {
  height: 53px;
  margin: 0;
  border-radius: 8px;
  border-width: 0;
  background-color: black;
  color: #ffffff;
  width: 137px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: "Archivo", sans-serif;
}

.contact-us-btn:hover {
  cursor: pointer;
  opacity: 0.8;
  transition: all 200ms;
}
