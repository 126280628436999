.icon-with-label-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;

  @media (max-width: 600px) {
    span {
      font-size: 12px;
    }
  }
}

.icon-container {
  display: flex;
  background-color: #1943cd;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  margin-right: 12px;

  @media (max-width: 600px) {
    font-size: 12px;
  }
}
