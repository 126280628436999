.fleet-details-container {
  width: 100%;
  height: 100vh;
  position: relative;
  scroll-snap-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/images/FleetDetails.png");

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.fleet-details-left-content-container {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    width: 100%;
    height: 50%;
  }
}

.fleet-details-right-content-container {
  height: 100%;
  width: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    width: 100%;
    height: 50%;
  }
}

.fleet-details-overview-container {
  display: flex;
  flex-direction: column;
  width: 507px;
  height: 698px;
  background-color: #001456;
  border-radius: 8px;

  @media (max-width: 600px) {
    height: 45vh;
    width: 70vw;
  }
}

.fleet-details-h1 {
  color: #ffffff;
  font-size: 48px;
  font-weight: normal;
  margin: 0;
  padding: 0;
  padding-top: 60px;
  padding-left: 60px;

  @media (max-width: 600px) {
    font-size: 24px;
    padding-top: 20px;
    padding-left: 30px;
  }
}
.fleet-details-overview-h1 {
  color: #8bc8ff;
  font-size: 48px;
  font-weight: normal;
  margin: 0;
  padding: 0;
  margin-bottom: 40px;
  padding-left: 60px;

  @media (max-width: 600px) {
    font-size: 24px;
    padding-left: 30px;
    margin-bottom: 20px;
  }
}

.fleet-details-first-paragraph {
  margin: 0;
  padding: 0;
  margin-bottom: 40px;
  font-weight: normal;
  color: white;
  font-size: 18px;
  line-height: 28px;
  padding-left: 60px;
  padding-right: 60px;

  @media (max-width: 600px) {
    font-size: 12px;
    padding-left: 30px;
    padding-right: 30px;
    line-height: 18px;
    margin-bottom: 15px;
  }
}

.fleet-details-second-paragraph {
  font-weight: normal;
  margin: 0;
  padding: 0;
  color: white;
  font-size: 18px;
  line-height: 28px;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 60px;

  @media (max-width: 600px) {
    font-size: 12px;
    padding-left: 30px;
    padding-right: 30px;
    line-height: 18px;
    padding-bottom: 30px;
  }
}

.fleet-details-units-list-container {
  height: 685px;
  width: 538px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 600px) {
    width: 80vw;
    height: 40vh;
  }
}
