.about-us-container {
  width: 100%;
  height: 100vh;
  position: relative;
  scroll-snap-align: center;
  background-image: url("../../assets/images/AboutUs.png");
  background-repeat: no-repeat;
  background-position: right;

  @media (max-width: 600px) {
    background-image: none;
    border-top: 1px solid lightgray;
  }
}

.about-us-left-content-container {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    width: 100%;
  }
}

.about-us-right-content-container {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    display: none;
  }
}

.about-us-content-container {
  display: flex;
  flex-direction: column;
  width: 560px;
  height: 700px;

  @media (max-width: 600px) {
    width: 80%;
    height: 80%;
  }
}

.about-us-founded-label {
  color: #2654ee;
  font-size: 48px;
  font-weight: normal;
  margin: 0;
  padding: 0;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    font-size: 32px;
  }
}

.about-us-founded-label label {
  color: #1b1b1b;
}

.about-us-description {
  line-height: 25px;
  margin-bottom: 40px;
  color: #747379;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}
