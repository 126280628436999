.feed-back-tile-container {
  max-height: 216px;
  min-height: 216px;
  width: 370px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;

  img {
    width: 96px;
    height: 16px;
  }

  p {
    color: #1b1b1b;
    line-height: 25px;
  }

  .feed-back-tile-company-name {
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }

  .feed-back-tile-employee-name {
    font-size: 14px;
    line-height: 14px;
    margin: 0;
    padding: 0;
  }
}
