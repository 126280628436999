.client-cards-container {
  width: 250px;
  height: 150px;
  background-color: #f4f5f6;
  border-radius: 8px;
  margin-right: 16px;

  display: flex;
  flex-direction: row;

  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;

  label {
    color: #1b1b1b;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 4px;
  }

  .client-address {
    color: #747379;
    font-weight: normal;
    font-size: 16px;
  }

  img {
    height: 90%;
    width: 50%;
    object-fit: contain;
    margin-right: 15px;
    background-color: white;
    border-radius: 8px;
  }

  .client-description-container {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
}
