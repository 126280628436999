.operational-areas-container {
  background-color: #1943cd;
  width: 100%;
  height: 100vh;
  position: relative;
  scroll-snap-align: center;
  display: flex;
  flex-direction: row;
}

.operational-areas-left-content-container {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 632px;
    height: 765px;
  }
}

.operational-areas-right-content-container {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    color: #ffffff;
    font-size: 48px;
    font-weight: normal;
    margin: 0;
    padding: 0;
    padding-left: 150px;
  }
  .areas-label {
    color: #ffffff;
    padding-left: 150px;
  }

  p {
    color: #ffffff;
    width: 326px;
    padding-left: 150px;
    line-height: 25px;
  }
}
