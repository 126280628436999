.units-tiles-container {
  border-bottom: 1px solid #8b8888;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  @media (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.units-tiles-row-container {
  height: 56px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 600px) {
    height: 32px;
  }
}

.units-tiles-label {
  color: #1b1b1b;
  font-size: 20px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.units-tiles-number-of-units {
  color: #747379;
  font-size: 20px;

  @media (max-width: 600px) {
    font-size: 14px;
  }
}

.label-bold {
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;

  @media (max-width: 600px) {
    font-size: 12px;
  }
}

.m8 {
  @media (max-width: 600px) {
    label {
      font-size: 12px;
    }
  }
}
