.mission-vision-container {
  width: 100%;
  height: 100vh;
  position: relative;
  scroll-snap-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mission-vision-content-container {
}

.our-mission-container {
  background-color: #1943cd;
  height: 359px;
  width: 504px;
  border-radius: 8px;
  margin-right: 24px;

  h1 {
    color: #ffffff;
    font-size: 48px;
    font-weight: normal;
    margin: 0;
    padding: 0;
    padding-top: 52px;
    padding-left: 52px;
    padding-right: 52px;
    margin-bottom: 16px;
  }

  ul {
    padding-left: 72px;
    padding-right: 52px;
  }

  li {
    color: #ffffff;
    line-height: 25px;
  }
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
}

.our-vision-container {
  background-color: #1943cd;
  height: 359px;
  width: 398px;
  border-radius: 8px;
  margin-left: 24px;

  h1 {
    color: #ffffff;
    font-size: 48px;
    font-weight: normal;
    margin: 0;
    padding: 0;
    padding-top: 82px;
    padding-left: 52px;
    padding-right: 52px;
    margin-bottom: 16px;
  }

  p {
    color: #ffffff;
    line-height: 25px;
    padding-left: 32px;
    padding-right: 32px;
  }
}
