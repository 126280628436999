.hear-from-our-clients-container {
  width: 100%;
  height: 100vh;
  position: relative;
  scroll-snap-align: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: #f7f7f8;
}

.hear-from-our-client-content-container {
  display: flex;
  flex-direction: column;
}

.hear-from-our-satisfied-client-container {
  position: relative;
  padding-right: 80px;
  h1 {
    font-size: 48px;
    font-weight: normal;
    margin: 0;
    padding: 0;
  }

  .satisfied-clients-label {
    color: #2654ee;
  }

  p {
    color: #747379;
    font-size: 18px;
    line-height: 25px;
  }

  img {
    position: absolute;
    right: 20px;
    bottom: 1px;
  }
}

.feedback-list-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 120px;
}
