.container {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  scroll-snap-type: y mandatory;
  height: 100vh;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* &::-webkit-scrollbar {
    display: none;
  } */
  overflow-x: hidden;
  @media (max-width: 600px) {
    overflow-x: hidden;
  }
}
