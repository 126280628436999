.hse-container {
  width: 100%;
  height: 100vh;
  position: relative;
  scroll-snap-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1943cd;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  .hse-left-container {
    width: 50vw;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;

    h1 {
      color: #2654ee;
    }

    p,
    li {
      line-height: 25px;
      margin-bottom: 40px;
      color: #747379;
      font-size: 18px;
    }
  }

  .hse-right-container {
    width: 50vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
  }

  h1 {
    color: #ffffff;
  }

  p,
  li {
    line-height: 25px;
    margin-bottom: 40px;
    color: #ffffff;
    font-size: 18px;
  }
}
